import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from '@emotion/styled'
import { ADOCEAN_IDS } from '../../config'

const Ads = (props) => {
  const StyledAd = styled('div')`
    width: 100%;
    padding-top: 16px;
    position: relative;
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    margin-bottom: 16px;
  `
  const StyledAdText = styled('span')`
    width: 100%;
    padding: 0 8px;
    color: #999;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: .5px;
  `
  let showBanner = true
  let bannerType = isMobile ? 'mobile' : 'desktop'

  if (props.only) {
    showBanner = props.only === bannerType
    bannerType = props.only
  }
  const adoId = ADOCEAN_IDS[bannerType][props.placement]
  const adoMasterProp = props.location ? props.location : 'subpage'
  const adoMaster = ADOCEAN_IDS[bannerType].master[adoMasterProp]
  setTimeout(() => {
    if (typeof ado !== 'undefined') {
      if ((!ado.masterSlaves[adoMaster] || ado.masterSlaves[adoMaster]) && showBanner) {
        if (!ado.masterSlaves[adoMaster]) {
          const tagStr = document.querySelector('meta[name="keywords"]') ? document.querySelector('meta[name="keywords"]').getAttribute('content').replace(/\s/g, '') : ''
          ado.master({
            id: adoMaster, server: 'indexhu.adocean.pl', vars: typeof customTarget !== 'undefined' ? customTarget : '', keys: tagStr,
          })
        }
        if (document.getElementById(`banner-${adoId}`)) {
          if (typeof ado !== 'undefined' && ado.masterSlaves[adoMaster] && !ado.masterSlaves[adoMaster].includes(adoId)) {
            ado.slave(adoId, { myMaster: adoMaster })
          }

          document.getElementById(`banner-${adoId}`).style.display = 'flex'
        }
      }
    }
  }, 1000)
  return (
    <>
      <StyledAd
        id={`banner-${adoId}`}
        className="iap"
      >
        <div
          id={adoId}
          key={adoId}
        />
        <StyledAdText>hirdetés</StyledAdText>
      </StyledAd>
    </>
  )
}

export default Ads
