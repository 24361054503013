import React from 'react'
import { Grid, GridItem } from '../ui'

const PageLayout = ({
  children, spacing = 2, sideNode, ...props
}) => (
  <Grid
    {...props}
    mx={spacing * -1}
  >
    <GridItem
      px={spacing}
      margin="0 auto"
      maxWidth={[325, 760]}
      width={[1, 1, 2 / 3]}
    >
      {children}
    </GridItem>
    <GridItem
      px={spacing}
      margin="0 auto"
      maxWidth={[325, 760]}
      width={[1, 1, 1 / 3]}
      display={['none', 'block']}
      flex={['initial', 'initial', 1]}
    >
      {sideNode}
    </GridItem>
  </Grid>
)

export default PageLayout
